// extracted by mini-css-extract-plugin
export var BookListingItem = "BookListingItem-module--BookListingItem--sbNbh";
export var termHighlight = "BookListingItem-module--termHighlight--WOE5B";
export var abstract = "BookListingItem-module--abstract--G8RoL";
export var nsfw = "BookListingItem-module--nsfw--V8IcP";
export var nsfwMessage = "BookListingItem-module--nsfw-message--mT30d";
export var show = "BookListingItem-module--show--ZxvqT";
export var coverImage = "BookListingItem-module--coverImage--fkE+J";
export var contentAuthors = "BookListingItem-module--contentAuthors--NDNBD";
export var category = "BookListingItem-module--category--AsF4R";
export var collection = "BookListingItem-module--collection--vUy37";
export var meta = "BookListingItem-module--meta--P1MA-";
export var nsfwTag = "BookListingItem-module--nsfwTag--snxUO";