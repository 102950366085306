import React from "react"
import clsx from "clsx"
import * as style from "./ContentFilter.module.sass"

interface ContentFilterProps {
	searchLabel?: string
	placeholderText?: string
	className?: string
	handelInputChange: (e:React.ChangeEvent) => void
}

const ContentFilter: React.FC<ContentFilterProps> = ({ 
	searchLabel="Search",
	placeholderText="Enter search term", 
	className,
	handelInputChange
}) => {

	const rootClassName = clsx([style.ContentFilter, "ContentFilter	", className])

	return (
		<div data-testid='ContentFilter' className={rootClassName}>
			<label htmlFor='filterText'>{searchLabel}</label>
			<input
				id='filterText'
				name='filterText'
				onChange={handelInputChange}
				placeholder={placeholderText}
				type='text'/>
		</div>
	)

}

export default ContentFilter
