import React, { useState } from 'react'
import clsx from 'clsx'
import * as style from './PageOverview.module.sass'
// import Seo from '../Seo'

interface PagOverviewProps {
	children: any
	className?: string
}

const PageOverview: React.FC<PagOverviewProps> = ({
	children,
	className,
}) => {

	const classNames = clsx([
		className,
		style.PageOverview,
		'PageOverview'
	])

	return (
		<>

			{/* <Seo title={`${heading}`} /> */}

			<section data-testid='PageOverview' className={classNames}>
				{children}
			</section>

		</>
	)
}

export default PageOverview
