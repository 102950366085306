import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStateValue } from "../State"
import NsfwListingItem from "../NsfwListingItem"
import CommaList from "../../components/CommaList"
import * as style from "./BookListingItem.module.sass"
import { highlightTerm } from "../../modules/searchTools"
import clsx from "clsx"

const BookListingItem = ({
	title,
	subTitle,
	isNsfw,
	contentAuthors,
	contentCoverImage,
	description,
	goodReadLink,
	filterTerm
}) => {

	const [state] = useStateValue()
	const showNsfwContent = state.nsfw.show

	if (isNsfw && !showNsfwContent) {

		return <NsfwListingItem /> 
	
	} 
	
	const coverImageGatsbyImage = getImage(contentCoverImage)
	const rootClassName = clsx({
		[style.BookListingItem]: true,
	})

	return (

		<div className={rootClassName}>

			<div className="imageContainer">

				{coverImageGatsbyImage && 
					<GatsbyImage
						alt={title}
						title={title}
						className={style.coverImage}
						image={coverImageGatsbyImage} />
				}

			</div>

			<div>

				<h3>
					{highlightTerm(filterTerm, title, style.termHighlight)}
					{subTitle && <>{` `}<em>{highlightTerm(filterTerm, subTitle, style.termHighlight)}</em></>}
				</h3>

				{contentAuthors && 
					<CommaList prefix="by" highlightText={filterTerm} className={style.contentAuthors} items={contentAuthors} />
				}

				{description &&
					<p className={style.abstract}>{description}</p>
				}

				{goodReadLink &&
					<a target="_blank" href={goodReadLink} rel="noreferrer">View on GoodReads</a>
				}

			</div>

		</div> 

	)


}

export default BookListingItem