import React from "react"
import BookListingItem from "../BookListingItem"
import { motion, AnimatePresence } from "framer-motion"
import * as style from "./BookListing.module.sass"
import clsx from "clsx"

const BookListing = ({ skipPostID, posts, filterTerm }) => {

	const itemAnimationStates = {
		initial: {
			opacity: 0,
			y: -50
		},
		animate: {
			opacity: 1,
			y: 0,
		}
	}

	return (

		<div className={style.BookListing}>
			<AnimatePresence animateOnMount>
				{posts.filter(post => post.node.frontmatter.title.length > 0).map(({ node: post }, i) => {

					if (post.id !== skipPostID) {

						const { frontmatter, fields, id } = post
						const isFullArticle = frontmatter.abstract ? true : false

						const rootClassName = clsx({
							[style.doubleCoulumn]: isFullArticle,
						})

						return (
							<motion.div className={rootClassName} key={id} transition={{ delay: i * .07 }} variants={itemAnimationStates} initial="initial" animate="animate">
								<BookListingItem filterTerm={filterTerm} {...fields} {...frontmatter} />
							</motion.div>
						)
					
					}
					return null
				
				})}
			</AnimatePresence>
		</div>

	)

}

export default BookListing
