import React from "react"
import clsx from "clsx"
import * as style from "./CommaList.module.sass"

interface CommaListProps {
	items: [string]
	prefix?: string
	className?: string
}

const CommaList: React.FC<CommaListProps> = ({
	items,
	prefix=null,
	className=null
}) => {

	const classNames = clsx([
		className,
		style.CommaList,
		"CommaList"
	])

	return (
		<div data-testid='CommaList' className={classNames}>

			{prefix && <>{prefix}{` `}</>}
			{items.map( (item) => <span key={item}>{item}</span> )}

		</div>
	)

}

export default CommaList
