import React, { useState } from "react"
import { graphql } from "gatsby"
import BookListing from "../../components/BookListing"
import PageTransition from "../../components/PageTransition"
import PageHeader from "../../components/PageHeader/PageHeader"
import PageOverview from "../../components/PageOverview"
import "../index.sass"
import ContentFilter from "../../components/ContentFilter"
import { termInString } from "../../modules/searchTools"

interface InterestingPageProps {

	location: any
	data: any

}

const InterestingPage: React.FC<InterestingPageProps> = ({ data, location }) => {

	const allBooks = data.allMarkdownRemark.edges
	const [filter, setFilter] = useState("")

	const handelInputChange = (e:React.ChangeEvent<HTMLInputElement>) => {

		setFilter(e.target.value)
		console.info(e.target.value)

	}

	const booksFiltered = filter ? allBooks.filter((book) => {

		if (termInString(filter, book.node.frontmatter.title)) {

			return true

		}
		if (book.node.frontmatter.subTitle && termInString(filter, book.node.frontmatter.subTitle)) {

			return true

		}
		if (book?.node.frontmatter?.keywords && book?.node.frontmatter?.keywords.some( ( author ) => termInString(filter, author) )) {

			return true

		}
		return book?.node.frontmatter?.contentAuthors.some( ( author ) => termInString(filter, author) )

	}) : allBooks

	return (

		<PageTransition location={location}>
			<PageHeader sectionName="Recommended" title="Books" description="A collection of interesting books" />
			<PageOverview>
				<p>A collection of books often referenced or recommended within the community. These books are on a wide range of subjects and a presented here as a reference. <strong>The views and opinions expressed in these books are not explicitly the views and options of Deviant Robot or the community.</strong></p>
			</PageOverview>
			<ContentFilter placeholderText="Filter on book titles or authors" searchLabel="Filter books" handelInputChange={handelInputChange} />
			<BookListing filterTerm={filter} posts={booksFiltered} />
		</PageTransition>

	)

}

export default InterestingPage

export const pageQuery = graphql`

	query {

		allMarkdownRemark(

			filter: {
				fields: {
					category: {eq: "Recommended"}, 
					collection: {eq: "Books"}
				}
				frontmatter: {
					draft: {ne: true}
				}
			}

			sort: {
				order: DESC, fields: [frontmatter___title]
			}

		) {
			edges {
				node {
					id
					fields {
						category
					}
					frontmatter {
						title
						contentAuthors
						goodReadLink
						keywords
						description
						subTitle
						contentCoverImage{
							childImageSharp {
								gatsbyImageData(
									width: 250
									placeholder: BLURRED
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
			}
		}
	}

`